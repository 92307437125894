/* Variables de estilo */
:root {
  --primary-color: blue;
  --secondary-color: red;
  --font-size-label: 1.2rem;
  --font-size-input: 1rem;

  --font-weight-label: 700;
  --font-weight-input: 700;

  --font-color-label: #fff;
  --font-color-input: #000;
  --white-color: #fff;
  --standard-color: #7b8ab8;

  --hover-standard-color: rgb(240,240,240);
  --hover-standard-border-color: rgba(255,255,255,0.8);
  --disabled-standard-color: rgb(180,180,180);

  --active-green-button: green;
  --hover-green-button: rgb(0, 174, 0);
  --hover-green-button-border: rgba(0,174,0,0.8);
  --disabled-green-button: #063600;

  --active-red-button: rgb(181, 0, 0);
  --hover-red-button: red;
  --hover-red-button-border: rgba(174, 0, 0,0.8);
  --disabled-red-button: rgb(92, 0, 0);

  --active-blue-button: rgb(0, 0, 209);
  --hover-blue-button: rgb(0, 64, 255);
  --hover-blue-button-border: rgb(0,0,255,0.8);
  --disabled-blue-button: rgb(0, 0, 144);
}
